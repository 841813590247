import WOW from 'wowjs';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import Navbar from '../../components/navbar';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';

const Home = () => {

    const [activeSection, setActiveSection] = useState('');
    const [textToCopy, setTextToCopy] = useState(''); // The text you want to copy
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied
    useEffect(() => { new WOW.WOW({ live: true }).init() }, []);

    const setVisible = (active) => {
        if (activeSection === active) setActiveSection('');
        else setActiveSection(active);
    };


    const tokenSlider = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 9000,
        autoplaySpeed: 0,
        cssEase: 'linear',
        arrows: false,
        rtl: true,
    };

    const memesSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
      };

    return (
        <div className='evil-kermit-page'>
            <div>
                <Navbar activeSection={activeSection} />
                {/* Banner */}
                <VisibilitySensor onChange={() => setVisible('Banner')}>
                    <section className="banner-section" id="home">
                        <div className='banner-video'>
                            <img src={require("../../static/images/website-first-section.gif")} alt="" />
                        </div>
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className='banner-left-area'>
                                        <div className="banner-text wow slideInLeft" data-wow-delay='100ms'>
                                            <h1>evil<br /><span>kermit </span></h1>
                                            <p>The DARK side never sleeps..</p>
                                        </div>
                                        {/* <div className="btn-area">
                                        <ul>                                    
                                            <li><HashLink to="#joinme" smooth className="btn-style-one"><span>join us</span></HashLink> </li>
                                        </ul>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className='commit-box'>
                                        <p>I am your best friend..!</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="slider-container marque-area">
                            <Slider {...tokenSlider} className='wow fadeInLeft'>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            </Slider>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Whats Section */}
                <VisibilitySensor onChange={() => setVisible('Memes')}>
                    <section className='memes-sec' id="memes-sec">
                        <div className='auto-container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='sec-title wow fadeInUp'>
                                        <h2>memes</h2>
                                    </div>
                                    <div className="mames-slider">
                                        <Slider {...memesSlider} className='wow fadeInLeft'>
                                            <div>
                                                <div className='item'>
                                                    <div className='img-box'>
                                                        <img src={require("../../static/images/memes-img.png")} alt="" />
                                                    </div>
                                                    <div className='text-box'>
                                                        <p className='left-content'>Seize the opportunity, buy immediately!</p>
                                                        <p className='right-content'>Hold off on buying for now.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            <div className='item'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/memes-img.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <p className='left-content'>"Buy now, prices are low!"</p>
                                                    <p className='right-content'>"It's not the right time to invest."</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='item'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/memes-img.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <p className='left-content'>...</p>
                                                    <p className='right-content'>overthink it.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='item'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/memes-img.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <p className='left-content'>"Buy now, prices are low!"</p>
                                                    <p className='right-content'>"It's not the right time to invest."</p>
                                                </div>
                                            </div>
                                        </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-container marque-area">
                            <Slider {...tokenSlider} className='wow fadeInLeft'>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            </Slider>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Whats Section */}
                <VisibilitySensor onChange={() => setVisible('Toknomics')}>
                    <section className='toknomics-sec' id="toknomics-sec">
                        <div className='auto-container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='sec-title wow zoomIn'>
                                        <h2>tokenomic</h2>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='toknomics-box wow fadeInLeft'>
                                        <div className='img-box'>
                                            <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                            <div className='text-box'>
                                                <h4>Token Address</h4>
                                                <p>9gCJB....pUMp</p>
                                                <CopyToClipboard text='9gCJBVuhT4uFtwaBVnB6GGcsC2wTYsJZm48REdspUMp' onCopy={onCopyText}>
                                                    <button className='icon'><i><img src={require("../../static/images/file.png")} alt="" /></i></button>
                                                </CopyToClipboard>
                                                {copyStatus && <p style={{marginTop: '80px', fontSize: '9px'}}>Wallet Address Copied</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='toknomics-box wow fadeInUp'>
                                        <div className='img-box'>
                                            <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                            <div className='text-box'>
                                                <h4>100% LP Burnt</h4>
                                                <i className='icon'><img src={require("../../static/images/fire.png")} alt="" /></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='toknomics-box wow fadeInDown'>
                                        <div className='img-box'>
                                            <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                            <div className='text-box'>
                                                <h4>Total Supply <br /> 100 billion</h4>
                                                <i className='icon'><img src={require("../../static/images/doller.png")} alt="" /></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='toknomics-box wow fadeInRight'>
                                        <div className='img-box'>
                                            <img src={require("../../static/images/toknomics-img.png")} alt="" />
                                            <div className='text-box'>
                                                <h4>Mint Authority <br />Revoked</h4>
                                                <i className='icon'><img src={require("../../static/images/user.png")} alt="" /></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bottom-img wow fadeInDown'>
                            <img src={require("../../static/images/toknomics-bottom-img.png")} alt="" />
                        </div>
                        <div className="slider-container marque-area">
                            <Slider {...tokenSlider} className='wow fadeInLeft'>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            <div>
                                <p>$evil</p>
                            </div>
                            </Slider>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Join Section */}
                <VisibilitySensor onChange={() => setVisible('Join')}>
                    <section className='join-sec' id="joinme">
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className="col-lg-7 col-md-12">
                                    <div className="heading-memes-area-left wow fadeIn">
                                        <div className='img-box'>
                                            <div className='text-box'>
                                                <p>join our community now and dominate the meme coin world!</p>
                                            </div>
                                            <img src={require("../../static/images/join-img-1.png")} alt="" />
                                        </div>
                                        <div className='img-box style-two'>
                                            <div className='text-box'>
                                                <p>Maybe later. i love to waste time.</p>
                                            </div>
                                            <img src={require("../../static/images/join-img-2.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-12'>
                                    <div className='sec-title wow zoomIn'>
                                        <h2><span>join the </span>community </h2>
                                        <ul>
                                            <li>
                                                <a href='https://t.me/EvilKermitMeme' target='_blank'><img src={require("../../static/images/menu-telegram.png")} alt="" /></a>
                                            </li>
                                            <li>
                                                <a href='https://x.com/EvilkermitMeme' target='_blank'><img src={require("../../static/images/menu-x.png")} alt="" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>
            </div>
        </div >
    );
}

export default Home;